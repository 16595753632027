<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
<!--     <img
        src="assets/img/logo.png"
        alt="Gssero Logo"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
    /> -->
    <span class="brand-text font-weight-light"></span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    
    <p class="login-box-msg"><img src="../../../assets/img/logo.png"></p>


    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of menu"
                [menuItem]="item"
            ></app-menu-item>

        </ul>
    </nav>
</div>
