import { MakineService } from './../services/makine.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-makineurun',
  templateUrl: './makineurun.component.html',
  styleUrls: ['./makineurun.component.scss']
})
export class MakineurunComponent implements OnInit {

  datasource: any;
  constructor(private service: MakineService) { }

  ngOnInit() {
    this.service.getAll('urunlerlist').subscribe((data) => {
      this.datasource = data;
  });
  }


}
