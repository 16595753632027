import { FrimaService } from './../firmalist/services/firma.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-firmacreate',
  templateUrl: './firmacreate.component.html',
  styleUrls: ['./firmacreate.component.scss']
})
export class FirmacreateComponent implements OnInit {

  formGroup: FormGroup;

  datasourceusertype: any;
  datasourceuuserlist: any;
  
  user = new FormGroup({
    id: new FormControl(),
    firmaadi: new FormControl(Validators.compose([])),
  });


  constructor(    private fb: FormBuilder,
    private service: FrimaService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");


    if (id) {
      this.service
        .getById2("firmaid", Number(id), "id")
        .subscribe((data) => {
          this.user.patchValue(data[0]);
          
        });
    }



  }


  save() {
    const id = this.route.snapshot.paramMap.get("id");

    if (id) {
       this.service
        .update("updatefirma", this.user.value)
        .subscribe((result) => {}); 
    } 
    else{
      this.service
      .create("firmacreate", this.user.value)
      .subscribe((result) => {}); 
    }

    this.router.navigate(["/firmalist"]);
  }
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation: string, controlName: string) {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.dirty || control.touched;
  }

}
