<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Kullanıcı Güncelleme</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Kulanıcı Güncelleme</h3>

      </div>
      <div class="card-body">

        <form action="#" class="form-horizontal" [formGroup]="user" (ngSubmit)="save()">
          <div class="card-body">
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-2 col-form-label">Adı</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="name"
                  formControlName="name" />
              </div>
            </div>
            <div class="form-group row">
              <label for="email" class="col-sm-2 col-form-label">E-Posta</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="email"
                  formControlName="email" />
              </div>
            </div>
            <div class="form-group row">
              <label for="telefon" class="col-sm-2 col-form-label">Telefon</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="telefon"
                  formControlName="telefon" />
              </div>
            </div>


            
            <div class="form-group row">
              <label for="user_id" class="col-sm-2 col-form-label">Referans</label>
              <div class="col-sm-10">
                <dx-select-box [dataSource]="datasourceuuserlist" formControlName="user_id" name="user_id"
                  displayExpr="name" valueExpr="id" [searchEnabled]="true"></dx-select-box>
              </div>
            </div>

          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary font-weight-bold mr-2" [disabled]="!user.valid">
              Kaydet
            </button>
            <a class="btn btn-default font-weight-bold mr-2" routerLink="/userlist">Vazgeç</a>
          </div>
          <!-- /.card-footer -->
        </form>


      </div>

      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->