import { UserListService } from './services/userlist.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  datasource: any;
  constructor(private service: UserListService,private router: Router, private route: ActivatedRoute) { this.cloneIconClick = this.cloneIconClick.bind(this)}

  ngOnInit() {
    this.service.getAll('userlist').subscribe((data) => {
      this.datasource = data;
  });
  }
  cloneIconClick(e) {
    this.router.navigate(["/useradd/"+e.row.data.id]);
  
  
  }
}
