import { AuthenticationService } from './../../guards/authentication.service';
import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding
} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public loginForm: FormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private router:Router,
        private auth:AuthenticationService,
    ) {}

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.loginForm = new FormGroup({
            email: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required)
        });
    }

     loginByAuth() {
        const email =this.loginForm.value.email;
        const password = this.loginForm.value.password;
    
        // console.log(email, password);
        this.auth.login(email, password).subscribe((res:any)=>{
          // console.log(res);
          localStorage.setItem('user', JSON.stringify(res))

    
          // redirect to dashboard
          this.router.navigate(['/dashboard']);
        },
        err=>{
            this.toastr.error("Hatalı Giriş");
        })
    }



    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
