import { DateTime } from 'luxon';

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-CountDownComponent',
  templateUrl: './CountDownComponent.component.html',
  styleUrls: ['./CountDownComponent.component.scss']
})
export class CountDownComponent implements OnInit, OnDestroy {
   @Input() clapCount: DateTime=0;
    private subscription: Subscription;
  
    public dateNow = new Date();
    //public dDay = new Date('11 16 2021 00:00:00');
    
    public  latest_date =this.datepipe.transform(this.clapCount, 'dd MM yyyy');

    public dDay = new Date(this.latest_date);

    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute  = 60;

    public timeDifference;
    public secondsToDday;
    public minutesToDday;
    public hoursToDday;
    public daysToDday;

    constructor(public datepipe: DatePipe){}
    private getTimeDifference () {
        this.timeDifference = this.dDay.getTime() - new  Date().getTime();
        this.allocateTimeUnits(this.timeDifference);
    }


  private allocateTimeUnits (timeDifference) {
        this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

    ngOnInit() {
       this.subscription = interval(1000)
           .subscribe(x => { this.getTimeDifference(); });
    }

   ngOnDestroy() {
      this.subscription.unsubscribe();
   }

}
