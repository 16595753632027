import { AuthenticationService } from '@guards/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@services/base-data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserListService extends BaseDataService<any> {


  private readonly apiControllerBirim: string = 'birim';
  apiUrl = environment.apiUrl;
constructor(httpClient: HttpClient,  auth: AuthenticationService) {     
  super(
  httpClient,
  environment.apiUrl,
  auth);
}



}
