import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timecount',
  templateUrl: './timecount.component.html',
  styleUrls: ['./timecount.component.scss']
})
export class TimecountComponent implements OnInit {
  @Input() clapCount;

  currentDate: any;
  targetDate: any;
  cDateMillisecs: any;
  tDateMillisecs: any;
  difference: any;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;;

  ngOnInit() {
    this.myTimer()
  }

  myTimer() {
    this.currentDate = new Date();
    this.targetDate = new Date(this.clapCount);
    this.cDateMillisecs = this.currentDate.getTime();
    this.tDateMillisecs = this.targetDate.getTime();
    this.difference = this.tDateMillisecs - this.cDateMillisecs;
    this.seconds = Math.floor(this.difference / 1000);
    this.minutes = Math.floor(this.seconds / 60);
    this.hours = Math.floor(this.minutes / 60);
    this.days = Math.floor(this.hours / 24);

    this.hours %= 24;
    this.minutes %= 60;
    this.seconds %= 60;
    this.hours = this.hours < 10 ? "0" + this.hours : this.hours;
    this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
    this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;

    
    setInterval(this.myTimer, 1000);
  }
    

}
