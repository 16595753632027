import {UrunlistService} from './../services/urunlist.service';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-uruncreate',
    templateUrl: './uruncreate.component.html',
    styleUrls: ['./uruncreate.component.scss']
})
export class UruncreateComponent implements OnInit {
    gridBoxValue: number;
    selectboxfirmavalue: number;
    isGridBoxOpened: boolean;
    gridColumns: any = [, 'Id', 'urunkartkod', 'urunkartaciklama'];
    gridDataSource: any;

    formGroup: FormGroup;

    datasourceurunkartliste: any;
    datasourcefirmaliste: any;

    user = new FormGroup({
        id: new FormControl(),
        gtip: new FormControl(),
        gtip_aciklama: new FormControl(),
        sbif: new FormControl(),
        fisno: new FormControl(),
        tarih: new FormControl(),
        firma_id: new FormControl(),
        urunkart_id: new FormControl(),
        girisadet: new FormControl(),
        birim: new FormControl(),
        kalem: new FormControl(),
        mensei: new FormControl(),
        birimfiyat: new FormControl(),
        tutar: new FormControl(),
        bakiye: new FormControl(),
        kalan: new FormControl()
    });

    constructor(
        private fb: FormBuilder,
        private service: UrunlistService,
        private router: Router,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');

        this.service.getAll('urunkartliste').subscribe((data) => {
            this.datasourceurunkartliste = data;
            this.gridDataSource = data;
        });

        this.service.getAll('firmalist').subscribe((data) => {
            this.datasourcefirmaliste = data;
        });

        if (id) {
            this.service
                .getById2('urunlerid', Number(id), 'id')
                .subscribe((data) => {
                    this.user.patchValue(data[0]);
                    this.gridBoxValue = data[0].Id;
                });
        }
    }

    save() {
        const id = this.route.snapshot.paramMap.get('id');


/*         this.user.controls.urunkart_id.setValue(this.gridBoxValue[0].Id);
        this.user.controls.firma_id.setValue(this.selectboxfirmavalue);
 */

console.log( this.user.value);

console.log( id);

this.user.controls.id.setValue(id);

        if (id) {
            this.service
                .create('updateurunler', this.user.value)
                .subscribe((result) => {});
        } else {
            this.service
                .create('urunlercreate', this.user.value)
                .subscribe((result) => {});
        }

        this.router.navigate(['/urunlist']);
    }
    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation: string, controlName: string) {
        const control = this.formGroup.controls[controlName];
        return (
            control.hasError(validation) && (control.dirty || control.touched)
        );
    }

    isControlTouched(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.dirty || control.touched;
    }

    onGridBoxOptionChanged(e) {
        if (e.name === 'value') {
            this.gridBoxValue = e.value[0].Id;
            this.isGridBoxOpened = false;
            this.ref.detectChanges();
        }
    }

    gridBox_displayExpr(item) {
        return item && `${item.urunkartkod} <${item.urunkartaciklama}>`;
    }

    onValueChanged(e) {
        const previousValue = e.previousValue;
        this.selectboxfirmavalue = e.value;
        // Event handling commands go here
    }
}
