import { AuthenticationService } from '@guards/authentication.service';
import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user;

    constructor(private appService: AuthenticationService, private router:Router) {}

    ngOnInit(): void {
        this.user = this.appService.user;
    }

    logout() {
        localStorage.removeItem('user');
        this.user = null;
        this.router.navigate(['/login']);
        this.appService.logout(true);
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
