<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Ürün Kartı Kaydetme</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Ürün Kartı Kaydetme</h3>

      </div>
      <div class="card-body">

        <form action="#" class="form-horizontal" [formGroup]="urunkart" (ngSubmit)="save()">
          <div class="card-body">
            


            <div class="form-group row">
              <label for="urunkartkod" class="col-sm-2 col-form-label">Kod</label>
              <div class="col-sm-10">
                <input type="tetx" class="form-control form-control-lg " placeholder="" name="urunkartkod"
                  formControlName="urunkartkod" />
              </div>
            </div>
            <div class="form-group row">
              <label for="urunkartaciklama" class="col-sm-2 col-form-label">Açıklama</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="urunkartaciklama"
                  formControlName="urunkartaciklama" />
              </div>
            </div>





          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary font-weight-bold mr-2" >
              Kaydet
            </button>
            <a class="btn btn-default font-weight-bold mr-2" routerLink="/urunkartliste">Vazgeç</a>
          </div>
          <!-- /.card-footer -->
        </form>


      </div>

      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
