import { User } from './../models/user';
import { UserListService } from './../services/userlist.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-useradd',
  templateUrl: './useradd.component.html',
  styleUrls: ['./useradd.component.scss']
})
export class UseraddComponent implements OnInit {
  formGroup: FormGroup;

  datasourceusertype: any;
  datasourceuuserlist: any;
  
  user = new FormGroup({
    id: new FormControl("", Validators.required),
    name: new FormControl(Validators.compose([])),
    email: new FormControl(Validators.compose([])),
    email_verified_at: new FormControl(Validators.compose([])),
    password: new FormControl(Validators.compose([])),
    remember_token: new FormControl(Validators.compose([])),
    created_at: new FormControl(Validators.compose([])),
    updated_at: new FormControl(Validators.compose([])),
    referal_code: new FormControl(Validators.compose([])),
    referal: new FormControl(Validators.compose([])),
    user_type: new FormControl(Validators.compose([])),
    career: new FormControl(Validators.compose([])),
    user_id: new FormControl(Validators.compose([])),
    telefon: new FormControl(Validators.compose([])),
  });


  constructor(    private fb: FormBuilder,
    private service: UserListService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");


    this.service.getAll("usertypelist").subscribe((data) => {
      this.datasourceusertype = data;
  
    });


    this.service.getAll("userlistall").subscribe((data) => {
      this.datasourceuuserlist = data;  
    });


    if (id) {
      this.service
        .getById2("getusershow", Number(id), "id")
        .subscribe((data) => {
          this.user.patchValue(data[0]);
          
        });
    }



  }


  save() {
    const id = this.route.snapshot.paramMap.get("id");

    if (id) {

       this.service
        .update("updateser", this.user.value)
        .subscribe((result) => {}); 
    } 

    this.router.navigate(["/sistemyoneticisi/bilimdalitanim-liste"]);
  }
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation: string, controlName: string) {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.dirty || control.touched;
  }
}
