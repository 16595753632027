import { DashBoardService } from './../dashboard/services/dashboard.service';
import {Component} from '@angular/core';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
    datasource: any;
username:string;
userdate:Date;
email:string;
    constructor(private service: DashBoardService) { }
  
    ngOnInit() {
  
        const user: any = localStorage.getItem('user');
    const userObj = JSON.parse(user);
        this.username=userObj.name;
        this.email=userObj.email;
        this.userdate=userObj. created_at;

    }
}
