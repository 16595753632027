<div class="container" id="pdfTable" #pdfTable>
  


  <div >
    

<!--     <table class="table table-bordered">
      <thead>
        <tr>
          <th>Sıra No</th>
          <th>G.T.P.No</th>
          <th>Maln Cinsi</th>
          <th>Menşei</th>
          <th>Üretilen Miktar</th>
          <th>Ölçü Birimi</th>
          <th>Kap Miktar</th>
          <th>Kap Cinsi</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of datasourceurunkart">
          <th>{{item.id}}</th>
          <th>Column2</th>
          <th>Column3</th>
        </tr>
      </tbody>
    </table>
 -->

               
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Sıra No</th>
          <th>G.T.P.No</th>
          <th>Giri Form No</th>
          <th>Maln Cinsi</th>
          <th>Menei</th>
          <th>Net Miktar</th>
          <th>Ölçü  Birimi    </th>
          <th>Brüt </th>
          <th>Değeri</th>
          <th>Para Birimi</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dataurunler">
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
          <th>{{item.id}}</th>
        </tr>
      </tbody>
    </table>
  </div>
  <button class="btn btn-primary" (click)="downloadAsPDF()">Export To PDF</button>
</div>