

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
      <div class="card">
          <div class="card-header">
              <h3 class="card-title">Malzeme Listesi</h3>


              <div class="card-tools">
                <a href="#/uruncreate" class="btn btn-block btn-primary">
                   Malzeme Ekle
              </a>
              </div>
          </div>
          <div class="card-body">
            <dx-data-grid id="gridContainer" [dataSource]="datasource" keyExpr="Id" [showBorders]="true"
            [showRowLines]="true" [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [remoteOperations]="false"
            [wordWrapEnabled]="true">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Arama..."></dxo-search-panel>
            <dxi-column dataField="gtip" caption="GTİP"></dxi-column>
            <dxi-column dataField="gtip_aciklama" caption="AÇIKLAMA"></dxi-column>
            <dxi-column dataField="sbif" caption="SBİF" > </dxi-column>
            <dxi-column dataField="fisno" caption="F.NO"> </dxi-column>
            <dxi-column dataField="tarih" caption="TARİH"> </dxi-column>
            <dxi-column dataField="firma" caption="FİRMA"> </dxi-column>
            <dxi-column dataField="kod" caption="KOD"> </dxi-column>
            <dxi-column dataField="malzeme" caption="MALEME"> </dxi-column>
            <dxi-column dataField="girisadet" caption="G.ADET"> </dxi-column>
            <dxi-column dataField="birim" caption="BİRİM"> </dxi-column>
            <dxi-column dataField="kalem" caption="KALEM"> </dxi-column>
            <dxi-column dataField="mensei" caption="MENŞEİ"> </dxi-column>
            <dxi-column dataField="birimfiyat" caption="B.FİYAT"> </dxi-column>
            <dxi-column dataField="tutar" caption="TUTAR"> </dxi-column>
            <dxi-column dataField="bakiye" caption="BAKİYE"> </dxi-column>
            <dxo-paging [pageSize]="12"></dxo-paging>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]"></dxo-pager>
          </dx-data-grid>
          </div>
          <!-- /.card-body -->
          <div class="card-footer"></div>
          <!-- /.card-footer-->
      </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
