import { FrimaService } from './services/firma.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-firmalist',
  templateUrl: './firmalist.component.html',
  styleUrls: ['./firmalist.component.scss']
})
export class FirmalistComponent implements OnInit {

  datasource: any;
  constructor(private service: FrimaService,private router: Router) {this.cloneIconClick = this.cloneIconClick.bind(this) }

  ngOnInit() {
    this.service.getAll('firmalist').subscribe((data) => {
      this.datasource = data;
  });
  }
  cloneIconClick(e) {
    this.router.navigate(["/firmacreate/"+e.row.data.Id]);
  
  
  }
}
