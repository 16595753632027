import {Component, OnInit, ViewChild} from '@angular/core';
import {FrimaService} from '@pages/firma/firmalist/services/firma.service';
import {DxDataGridComponent} from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { from } from 'rxjs';
import { flatMap, groupBy, reduce, switchMap, take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-urunrecetesiyukleme',
  templateUrl: './urunrecetesiyukleme.component.html',
  styleUrls: ['./urunrecetesiyukleme.component.scss']
})
export class UrunrecetesiyuklemeComponent implements OnInit {

  @ViewChild('dataGridVar', {static: false}) dataGrid: DxDataGridComponent;
  @ViewChild('dataGridVar1', {static: false}) dataGrid1: DxDataGridComponent;
  @ViewChild('dataGridVar2', {static: false}) dataGrid2: DxDataGridComponent;
  constructor(private service: FrimaService) {}

  ngOnInit() {}
  data: any;
  datasource: any;
  datasourcetrue: any;
  datasourcefalse: any;

  onFileChange(ev) {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, {type: 'binary'});
          jsonData = workBook.SheetNames.reduce((initial, name) => {
              const sheet = workBook.Sheets[name];
              initial[name] = XLSX.utils.sheet_to_json(sheet);
              return initial;
          }, {});
          this.data = JSON.stringify(jsonData);

          this.datasource = jsonData.Sheet;

          this.dataGrid.instance.refresh();
      };
      reader.readAsBinaryString(file);
  }

  export() {
      this.service.create('makineyukle', this.datasource).subscribe((result) => {
        
 
          this.datasourcetrue=result.allowdata;
          this.datasourcefalse=result.notallowdata;

          
          this.dataGrid1.instance.refresh();
          this.dataGrid2.instance.refresh();
      });  
  }


}
