import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MakineService } from '@pages/makine/services/makine.service';
import { Durum } from '@pages/urunlist/services/durumu.service';
import { UrunlistService } from '@pages/urunlist/services/urunlist.service';

@Component({
  selector: 'app-uretimbildirimcreate',
  templateUrl: './uretimbildirimcreate.component.html',
  styleUrls: ['./uretimbildirimcreate.component.scss']
})
export class UretimbildirimcreateComponent implements OnInit {

  formGroup: FormGroup;
  selectboxbirimvalue: number;
  selectboxmakinegrupvalue:number;
  datasourceusertype: any;
  datasourceuuserlist: any;
  datasourcemakineurun: any;
  startDate=new Date(Date.now());

  datasourcebirim: any;
  datasourcemakinegrup: any;

  user = new FormGroup({
      id: new FormControl(),
      tarih: new FormControl(Validators.compose([])),
      aciklama: new FormControl(Validators.compose([])),
      makinegrupid: new FormControl(Validators.compose([]))
  });

  constructor(
      private fb: FormBuilder,
      private service: MakineService,
      private router: Router,
      private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.user.get('tarih').patchValue(this.formatDate(new Date()));
      this.service.getAll('birimlist').subscribe((data) => {
          this.datasourcebirim = data;
      });

      this.service.getAll('makinegruplist').subscribe((data) => {
          this.datasourcemakinegrup = data;
      });


      const id = this.route.snapshot.paramMap.get('id');

      if (id) {
          this.service
              .getById2('makineid', Number(id), 'id')
              .subscribe((data) => {
                  this.user.patchValue(data[0]);
              });

          this.service
              .getById2('makineurunid', Number(id), 'id')
              .subscribe((data) => {
                  this.datasourcemakineurun=data;
              });
      }
  }

  save() {
      const id = this.route.snapshot.paramMap.get('id');

      if (id) {
          this.service
              .update('updatemakine', this.user.value)
              .subscribe((result) => {});
      } else {
          this.service
              .create('uretimbildirimkaydet', this.user.value)
              .subscribe((result) => {});
      }

      this.router.navigate(['/uretimbildirim']);
  }
  isControlValid(controlName: string): boolean {
      const control = this.formGroup.controls[controlName];
      return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
      const control = this.formGroup.controls[controlName];
      return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation: string, controlName: string) {
      const control = this.formGroup.controls[controlName];
      return (
          control.hasError(validation) && (control.dirty || control.touched)
      );
  }

  isControlTouched(controlName: string): boolean {
      const control = this.formGroup.controls[controlName];
      return control.dirty || control.touched;
  }


  ekle() {
    this.router.navigate(["/makineuruncreate/"+ this.route.snapshot.paramMap.get('id')]);
  
  
  }

  onValueChanged(e) {
      const previousValue = e.previousValue;
      this.selectboxbirimvalue = e.value;
      // Event handling commands go here
  }    
  onValueChanged1(e) {
      const previousValue = e.previousValue;
      this.selectboxmakinegrupvalue = e.value;
      // Event handling commands go here
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
}
