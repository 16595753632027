<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Makine Sipariş Ekleme/Güncelleme</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Makine Sipariş Ekleme/Güncelleme</h3>

      </div>
      <div class="card-body">

        <form action="#" class="form-horizontal" [formGroup]="user" (ngSubmit)="save()">
          <div class="card-body">
            
            <div class="form-group row">
              <label for="makine_id" class="col-sm-2 col-form-label">Ürün</label>
              <div class="col-sm-10">
                 <dx-select-box [dataSource]="datasourceurunkartliste" formControlName="makine_id" name="makine_id"
                displayExpr="makineadi" valueExpr="Id" [searchEnabled]="true"></dx-select-box> 
              </div>              
            </div>
            
    

            <div class="form-group row">
              <label for="makinesiparisadet" class="col-sm-2 col-form-label">Miktar</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="makinesiparisadet"
                  formControlName="makinesiparisadet" />
              </div>
            </div>

            <div class="form-group row">
              <label for="makinesiparistarih" class="col-sm-2 col-form-label">Tarih</label>
              <div class="col-sm-10">
                <input type="date" class="form-control form-control-lg " placeholder="" name="makinesiparistarih"
                  formControlName="makinesiparistarih"  />
              </div>
            </div>

            <div class="form-group row">
              <label for="durumu" class="col-sm-2 col-form-label">Durumu</label>
              <div class="col-sm-10">
                <dx-select-box [dataSource]="datasourcedurumu" formControlName="durumu" name="durumu"
                displayExpr="durumu" valueExpr="Id" [searchEnabled]="true"></dx-select-box> 
              </div>
            </div>

          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary font-weight-bold mr-2" [disabled]="!user.valid">
              Kaydet
            </button>
            <a class="btn btn-default font-weight-bold mr-2" routerLink="/makinesiparislist">Vazgeç</a>
          </div>
          <!-- /.card-footer -->
        </form>





      </div>

      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>


  

<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1>Malzeme Listesi</h1>
          </div>

      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
      <div class="card">
          <div class="card-header">
              <h3 class="card-title">Malzeme Listesi</h3>


              <div class="card-tools">
                <a href="#/uruncreate" class="btn btn-block btn-primary">
                  Yeni Malzeme Ekle
              </a>
              </div>
          </div>
          <div class="card-body">
            <dx-data-grid id="gridContainer" [dataSource]="dataurunler" keyExpr="Id" [showBorders]="true"
            [showRowLines]="true" [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [remoteOperations]="false"
            [wordWrapEnabled]="true">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Arama..."></dxo-search-panel>
            <dxi-column dataField="gtip" ></dxi-column>
            <dxi-column dataField="urunkartkod" ></dxi-column>
            <dxi-column dataField="urunkartaciklama" ></dxi-column>
            <dxi-column dataField="firmaadi" ></dxi-column>
            <dxi-column dataField="tarih" ></dxi-column>
            <dxi-column dataField="birimfiyat" ></dxi-column>
            <dxi-column dataField="makinesiparis_miktar"  > </dxi-column>
            <dxo-paging [pageSize]="12"></dxo-paging>

            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]"></dxo-pager>
          </dx-data-grid>
          </div>
          <!-- /.card-body -->
          <div class="card-footer"></div>
          <!-- /.card-footer-->
      </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->

  <!-- /.card -->
</section>
<!-- /.content -->