<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profil</h1>
            </div>

        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Profil</h3>

                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                    >
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <section class="content">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-12">
              
                          <!-- Profile Image -->
                          <div class="card card-primary card-outline">
                            <div class="card-body box-profile">

              
                              <h3 class="profile-username text-center">{{username}}</h3>
              
                              <p class="text-muted text-center">{{userdate | date: 'dd/MM/yyyy hh:mm'}}</p>
              
                              <ul class="list-group list-group-unbordered mb-3">
                                <li class="list-group-item">
                                  <b>Kullancı Adı</b> <a class="float-right">{{username}}</a>
                                </li>
                                <li class="list-group-item">
                                  <b>E-Posta</b> <a class="float-right">{{email}}</a>
                                </li>

                              </ul>
              
                            </div>
                            <!-- /.card-body -->
                          </div>
                          <!-- /.card -->
              
                          <!-- About Me Box -->

                          <!-- /.card -->
                        </div>
                        <!-- /.col -->

                        <!-- /.col -->
                      </div>
                      <!-- /.row -->
                    </div><!-- /.container-fluid -->
                  </section>
            </div>
            <!-- /.card-body -->
            <div class="card-footer"></div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
