import { DashBoardService } from './../../dashboard/services/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-urunkartekle',
  templateUrl: './urunkartekle.component.html',
  styleUrls: ['./urunkartekle.component.scss']
})
export class UrunkartekleComponent implements OnInit {

  formGroup: FormGroup;
  
  datasourceusertype: any;
  datasourceuuserlist: any;
  
  urunkart = new FormGroup({
    id: new FormControl("", Validators.required),
    urunkartkod: new FormControl(Validators.compose([])),
    urunkartaciklama: new FormControl(Validators.compose([])),
  });


  constructor(    private fb: FormBuilder,
    private service: DashBoardService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");



    if (id) {
      this.service
        .getById2("geturunkartid", Number(id), "id")
        .subscribe((data) => {
          this.urunkart.patchValue(data[0]);
          
        });
    }



  }


  save() {
    const id = this.route.snapshot.paramMap.get("id");

    if (id) {
       this.service
        .update("updateurunkart", this.urunkart.value)
        .subscribe((result) => {}); 
    } 
    else{
      this.service
      .create("urunkartcreate", this.urunkart.value)
      .subscribe((result) => {}); 
    }

    this.router.navigate(["/urunkartliste"]);
  }
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation: string, controlName: string) {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.dirty || control.touched;
  }

}
