import {
    Component,
    OnInit,
    Renderer2,
    OnDestroy,
    HostBinding
} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@guards/authentication.service';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'register-box';

    public registerForm: FormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    referal:any;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private auth:AuthenticationService,private router:Router,private route: ActivatedRoute
    ) {}


    errors = {
        name:null,
        email:null,
        password:null,
      }

    ngOnInit() {

        this.referal=this.route.snapshot.paramMap.get('id')


        this.renderer.addClass(
            document.querySelector('app-root'),
            'register-page'
        );
        this.registerForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            password: new FormControl(null, [Validators.required]),
            referal_code: new FormControl(),
            retypePassword: new FormControl(null, [Validators.required])
        });

        this.registerForm.controls.referal_code.setValue(
            this.referal
        );
    }

    registerByAuth() {
        const name = this.registerForm.value.name;
        const email = this.registerForm.value.email;
        const password = this.registerForm.value.password;
        const retypePassword = this.registerForm.value.retypePassword;
        const referal_code = this.registerForm.value.referal_code;

        this.auth.register(name,email,password,retypePassword,referal_code).subscribe((res)=>{
          // console.log(res);
           // redirect to dashboard
           this.router.navigate(['/login']);
        },
        (err)=>{
          this.errors = err.error.errors;
          // console.log(err.error.errors);
        })
    }


    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }
}
