import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FrimaService} from '@pages/firma/firmalist/services/firma.service';

@Component({
    selector: 'app-makinesiparislist',
    templateUrl: './makinesiparislist.component.html',
    styleUrls: ['./makinesiparislist.component.scss']
})
export class MakinesiparislistComponent implements OnInit {
    urunid:any;
    datasource: any;
    constructor(
        private route: ActivatedRoute,private service: FrimaService, private router: Router) {
        (this.cloneIconClick1 = this.cloneIconClick1.bind(this)),
            (this.cloneIconClick2 = this.cloneIconClick2.bind(this)),
            (this.cloneIconClick3 = this.cloneIconClick3.bind(this)),
            (this.cloneIconClick = this.cloneIconClick.bind(this)),
            (this.pdfIconClick = this.pdfIconClick.bind(this));
            (this.deleteIconClick = this.deleteIconClick.bind(this))
     
    }

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.urunid=id;

        this.service.getById2('makinesiparislist', Number(id), 'id').subscribe((data) => {
            this.datasource = data;
        });
    }


    cloneIconClick(e) {
        this.router.navigate(['/makinesipariscreate/' + e.row.data.id]);
    }

    cloneIconClick1(e) {
        this.router.navigate(['/exel/' + e.row.data.id]);
    }

    cloneIconClick2(e) {
        this.router.navigate(['/pdf/' + e.row.data.id]);
    }

    cloneIconClick3(e) {
        this.router.navigate(['/bildirim/' + e.row.data.id]);
    }

    pdfIconClick(e) {
        this.router.navigate(['/makinesiparispdf/' + e.row.data.id]);
    }

    deleteIconClick(e) {
        this.router.navigate(['/makinecreate/' +  this.route.snapshot.paramMap.get('id')]);
    }


    private static isChief(position) {
        return position && ["CEO", "CMO"].indexOf(position.trim().toUpperCase()) >= 0;
      };

    allowDeleting(e) {
        return !MakinesiparislistComponent.isChief(e.row.data.Position);
      }

      onRowRemoving(e) {
     
        this.service.getById2("makinesiparisdelete",e.key,"id").subscribe(res=>{
           
        });
      
      }
}
