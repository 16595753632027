import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Durum, Service } from '@pages/urunlist/services/durumu.service';
import { UrunlistService } from '@pages/urunlist/services/urunlist.service';

import ArrayStore from 'devextreme/data/array_store';
@Component({
  selector: 'app-makinesipariscreate',
  templateUrl: './makinesipariscreate.component.html',
  styleUrls: ['./makinesipariscreate.component.scss']
})


  
export class MakinesipariscreateComponent implements OnInit {


  gridBoxValue: number;
    selectboxfirmavalue: number;
    isGridBoxOpened: boolean;
    gridColumns: any = [, 'Id', 'urunkartkod', 'urunkartaciklama'];
    gridDataSource: any;

dataurunler:any;

    formGroup: FormGroup;

    datasourceurunkartliste: any;
    datasourcefirmaliste: any;
    datasourcedurumu:any;

    user = new FormGroup({
        id: new FormControl(),
        makinesiparistarih: new FormControl(),  
        makine_id: new FormControl(),        
        makinesiparisadet: new FormControl(),       
        uretimbildirim_id: new FormControl(),         
        durumu: new FormControl()
    });
    durums: Durum[];
        startDate=new Date(Date.now());


        durumlar = [
            { Id: 0, durumu: "Açık" },
            { Id: 1, durumu: "Kapalı" }
          ];


    constructor(
        private fb: FormBuilder,
        private service: UrunlistService,
        private router: Router,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef
    ) {


 


    }

    ngOnInit() {
        this.user.get('makinesiparistarih').patchValue(this.formatDate(new Date()));
        this.datasourcedurumu=this.durumlar;


        const id = this.route.snapshot.paramMap.get('id');

        this.service.getAll('makinelist').subscribe((data) => {
            this.datasourceurunkartliste = data;
        });

/*   
        if (id) {
            this.service
                .getById2('makinesiparisid', Number(id), 'id')
                .subscribe((data) => {
                    this.user.patchValue(data[0]);
                });
            this.service
                .getById2('makinesiparisurnlerid', Number(id), 'id')
                .subscribe((data) => {
                    this.dataurunler=data;
                });
        } */
    }

    save() {
        const id = this.route.snapshot.paramMap.get('id');
        this.user.controls.uretimbildirim_id.setValue(id );

        this.service
        .create("makinesipariscreate", this.user.value)
        .subscribe((result) => {}); 
        

        this.router.navigate(["/makinesiparislist/"+id]);
    }
    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation: string, controlName: string) {
        const control = this.formGroup.controls[controlName];
        return (
            control.hasError(validation) && (control.dirty || control.touched)
        );
    }

    isControlTouched(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.dirty || control.touched;
    }


    private formatDate(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
      }
}
