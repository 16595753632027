import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '@guards/authentication.service';
import {BaseDataService} from '@services/base-data.service';
import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MenusidebarService extends BaseDataService<any> {
    apiUrl = environment.apiUrl;
    constructor(httpClient: HttpClient, auth: AuthenticationService) {
        super(httpClient, environment.apiUrl, auth);
    }
}
