import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrunlistService } from '@pages/urunlist/services/urunlist.service';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
@Component({
  selector: 'app-makinesiparispdf',
  templateUrl: './makinesiparispdf.component.html',
  styleUrls: ['./makinesiparispdf.component.scss']
})
export class MakinesiparispdfComponent implements OnInit {

  title = 'htmltopdf';
   
  @ViewChild('pdfTable') pdfTable: ElementRef;

  gridBoxValue: number;
    selectboxfirmavalue: number;
    isGridBoxOpened: boolean;
    gridColumns: any = [, 'Id', 'urunkartkod', 'urunkartaciklama'];
    gridDataSource: any;

dataurunler:any;


datasourceurunkart: any;

    datasourceurunkartliste: any;
    datasourcefirmaliste: any;

 

    constructor(

        private service: UrunlistService,
        private router: Router,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');

        this.service.getAll('makinelist').subscribe((data) => {
            this.datasourceurunkart = data;
        });

        if (id) {
            this.service
                .getById2('makinesiparisid', Number(id), 'id')
                .subscribe((data) => {
                  this.datasourceurunkartliste=data;
                });
            this.service
                .getById2('makinesiparisurnlerid', Number(id), 'id')
                .subscribe((data) => {
                    this.dataurunler=data;
                });
        }
    }

    
    public downloadAsPDF() {
      const doc = new jsPDF();
      
      const pdfTable = this.pdfTable.nativeElement;
      
      var html = htmlToPdfmake(pdfTable.innerHTML);
        
      const documentDefinition = { content: html };
      pdfMake.createPdf(documentDefinition).open(); 
        
    }

}
