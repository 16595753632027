<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Makine Ekleme/Güncelleme</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Makine Ekleme/Güncelleme</h3>

      </div>
      <div class="card-body">

        <form action="#" class="form-horizontal" [formGroup]="user" (ngSubmit)="save()">
          <div class="card-body">

            <div class="form-group row">
              <label for="tarih" class="col-sm-2 col-form-label">Tarih</label>
              <div class="col-sm-10">
                <input type="date" class="form-control form-control-lg " placeholder="" name="tarih"
                  formControlName="tarih"  />
              </div>
            </div>

            <div class="form-group row">
              <label for="firma" class="col-sm-2 col-form-label">Gtip</label>
              <div class="col-sm-10">
                  <dx-select-box [dataSource]="datasourcemakinegrup" formControlName="makinegrupid" name="makinegrupid" (onValueChanged)="onValueChanged1($event)"
                  displayExpr="gtip" valueExpr="Id" [searchEnabled]="true"></dx-select-box>
              </div>
            </div>

            

            <div class="form-group row">
              <label for="aciklama" class="col-sm-2 col-form-label"> Açıklama </label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="aciklama"
                  formControlName="aciklama" />
              </div>
            </div>

          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary font-weight-bold mr-2" [disabled]="!user.valid">
              Kaydet
            </button>
            <a class="btn btn-default font-weight-bold mr-2" routerLink="/userlist">Vazgeç</a>
          </div>
          <!-- /.card-footer -->
        </form>


      </div>

      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->