import {MakineService} from './../services/makine.service';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-makinecreate',
    templateUrl: './makinecreate.component.html',
    styleUrls: ['./makinecreate.component.scss']
})
export class MakinecreateComponent implements OnInit {
    formGroup: FormGroup;
    selectboxbirimvalue: number;
    selectboxmakinegrupvalue:number;
    datasourceusertype: any;
    datasourceuuserlist: any;
    datasourcemakineurun: any;

    datasourcebirim: any;
    datasourcemakinegrup: any;

    user = new FormGroup({
        id: new FormControl(),
        makinekod: new FormControl(Validators.compose([])),
        makineadi: new FormControl(Validators.compose([])),
        birimid: new FormControl(Validators.compose([])),
        aciklama: new FormControl(Validators.compose([])),
        makinegrupid: new FormControl(Validators.compose([]))
    });



    constructor(
        private fb: FormBuilder,
        private service: MakineService,
        private router: Router,
        private route: ActivatedRoute
    ) {

        (this.deleteIconClick = this.deleteIconClick.bind(this)),
        (this.editIconClick = this.editIconClick.bind(this))
    }

    ngOnInit() {

        this.service.getAll('birimlist').subscribe((data) => {
            this.datasourcebirim = data;
        });

        this.service.getAll('makinegruplist').subscribe((data) => {
            this.datasourcemakinegrup = data;
        });


        const id = this.route.snapshot.paramMap.get('id');

        if (id) {
            this.service
                .getById2('makineid', Number(id), 'id')
                .subscribe((data) => {
                    this.user.patchValue(data[0]);
                });

            this.service
                .getById2('makineurunid', Number(id), 'id')
                .subscribe((data) => {
                    this.datasourcemakineurun=data;
                });
        }
    }

    save() {
        const id = this.route.snapshot.paramMap.get('id');

        if (id) {
            this.service
                .update('updatemakine', this.user.value)
                .subscribe((result) => {});
        } else {
            this.service
                .create('makinecreate', this.user.value)
                .subscribe((result) => {});
        }

        this.router.navigate(['/makinelist']);
    }
    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation: string, controlName: string) {
        const control = this.formGroup.controls[controlName];
        return (
            control.hasError(validation) && (control.dirty || control.touched)
        );
    }

    isControlTouched(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.dirty || control.touched;
    }


    ekle() {
      this.router.navigate(["/makineuruncreate/"+ this.route.snapshot.paramMap.get('id')]);
    
    
    }

    onValueChanged(e) {
        const previousValue = e.previousValue;
        this.selectboxbirimvalue = e.value;
        // Event handling commands go here
    }    
    onValueChanged1(e) {
        const previousValue = e.previousValue;
        this.selectboxmakinegrupvalue = e.value;
        // Event handling commands go here
    }


    
private static isChief(position) {
    return position && ["CEO", "CMO"].indexOf(position.trim().toUpperCase()) >= 0;
  };

    editIconClick(e) {
        this.router.navigate(['/makineuruncreate/' + e.row.data.Id]);
    }

    deleteIconClick(e) {
        this.router.navigate(['/makinecreate/' +  this.route.snapshot.paramMap.get('id')]);
    }


    allowDeleting(e) {
        return !MakinecreateComponent.isChief(e.row.data.Position);
      }

      onRowRemoving(e) {

        console.log(e.key);
        this.service.getById2("makineurundelete",e.key,"id").subscribe(res=>{
      
        });
      
      }
}
