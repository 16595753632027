import { UretimbildirimcreateComponent } from './pages/makinesiparis/uretimbildirimcreate/uretimbildirimcreate.component';
import { UretimbildirimComponent } from './pages/makinesiparis/uretimbildirim/uretimbildirim.component';
import { PdfComponent } from './pages/makinesiparis/pdf/pdf.component';
import { BildirimComponent } from './pages/makinesiparis/bildirim/bildirim.component';
import { ExelComponent } from './pages/makinesiparis/exel/exel.component';
import { UrunrecetesiyuklemeComponent } from './pages/dosyayukleme/urunrecetesiyukleme/urunrecetesiyukleme.component';
import { MalzemeyuklemeComponent } from './pages/dosyayukleme/malzemeyukleme/malzemeyukleme.component';
import { StokkartyuklemeComponent } from './pages/dosyayukleme/stokkartyukleme/stokkartyukleme.component';
import { FirmayuklemeComponent } from './pages/dosyayukleme/firmayukleme/firmayukleme.component';
import { MakinesiparispdfComponent } from './pages/makinesiparis/makinesiparispdf/makinesiparispdf.component';
import { MakinesiparislistComponent } from './pages/makinesiparis/makinesiparislist/makinesiparislist.component';
import { MakinesipariscreateComponent } from './pages/makinesiparis/makinesipariscreate/makinesipariscreate.component';
import { MakineuruncreateComponent } from './pages/makine/makineuruncreate/makineuruncreate.component';
import { UrunkartlisteComponent } from './pages/urunkart/urunkartliste/urunkartliste.component';
import { UruncreateComponent } from './pages/urunlist/uruncreate/uruncreate.component';
import { MakinecreateComponent } from './pages/makine/makinecreate/makinecreate.component';
import { MakinelistComponent } from './pages/makine/makinelist/makinelist.component';
import { FirmacreateComponent } from './pages/firma/firmacreate/firmacreate.component';
import { FirmalistComponent } from './pages/firma/firmalist/firmalist.component';
import { UrunlistComponent } from './pages/urunlist/urunlist.component';
import { UseraddComponent } from './pages/userlist/useradd/useradd.component';
import {UserlistComponent} from './pages/userlist/userlist.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { UrunkartekleComponent } from '@pages/urunkart/urunkartekle/urunkartekle.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'urunlist',
                component: UrunlistComponent
            },
            {
                path: 'uruncreate',
                component: UruncreateComponent
            },
            {
                path: 'uruncreate/:id',
                component: UruncreateComponent
            },
            {
                path: 'firmalist',
                component: FirmalistComponent
            },
            {
                path: 'firmacreate',
                component: FirmacreateComponent
            },
            {
                path: 'firmacreate/:id',
                component: FirmacreateComponent
            },
            {
                path: 'makinelist',
                component: MakinelistComponent
            },
            {
                path: 'makinecreate/:id',
                component: MakinecreateComponent
            },
            {
                path: 'makinecreate',
                component: MakinecreateComponent
            },
            {
                path: 'makineuruncreate/:id',
                component: MakineuruncreateComponent
            },
            {
                path: 'makineuruncreate',
                component: MakineuruncreateComponent
            },
            {
                path: 'makinesiparislist',
                component: MakinesiparislistComponent
            },
            {
                path: 'makinesiparislist/:id',
                component: MakinesiparislistComponent
            },
            {
                path: 'uretimbildirim',
                component: UretimbildirimComponent
            },
            {
                path: 'makinesipariscreate/:id/:id1',
                component: MakinesipariscreateComponent
            },
            {
                path: 'uretimbildirimcreate/:id',
                component: UretimbildirimcreateComponent
            },
            {
                path: 'uretimbildirimcreate',
                component: UretimbildirimcreateComponent
            },
            {
                path: 'makinesiparispdf/:id',
                component: MakinesiparispdfComponent
            },
            {
                path: 'makinesipariscreate/:id',
                component: MakinesipariscreateComponent
            },
            {
                path: 'useradd/:id',
                component: UseraddComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'urunkartliste',
                component: UrunkartlisteComponent
            },
            
            {
                path: 'firmayukleme',
                component: FirmayuklemeComponent
            },
            {
                path: 'stokkartyukleme',
                component: StokkartyuklemeComponent
            },
            {
                path: 'malzemeyukleme',
                component: MalzemeyuklemeComponent
            },
            {
                path: 'urunrecetesiyukleme',
                component: UrunrecetesiyuklemeComponent
            },
            {
                path: 'urunkartekle/:id',
                component: UrunkartekleComponent
            },
            
            {
                path: 'exel/:id',
                component: ExelComponent
            },
            {
                path: 'bildirim/:id',
                component: BildirimComponent
            },
            {
                path: 'pdf/:id',
                component: PdfComponent
            },
            {
                path: 'urunkartekle',
                component: UrunkartekleComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register/:id',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
