<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Makine Ekleme/Güncelleme</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Makine Ekleme/Güncelleme</h3>

      </div>
      <div class="card-body">

        <form action="#" class="form-horizontal" [formGroup]="user" (ngSubmit)="save()">
          <div class="card-body">
            <div class="form-group row">
              <label for="makinekod" class="col-sm-2 col-form-label">Kod</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="makinekod"
                  formControlName="makinekod" />
              </div>
            </div>

            <div class="form-group row">
              <label for="makineadi" class="col-sm-2 col-form-label"> Açıklama</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="makineadi"
                  formControlName="makineadi" />
              </div>
            </div>


            <div class="form-group row">
              <label for="firma" class="col-sm-2 col-form-label">Birim</label>
              <div class="col-sm-10">
                  <dx-select-box [dataSource]="datasourcebirim" formControlName="birimid" name="birimid" (onValueChanged)="onValueChanged($event)"
                  displayExpr="birimaciklama" valueExpr="Id" [searchEnabled]="true"></dx-select-box>
              </div>
            </div>

            <div class="form-group row">
              <label for="firma" class="col-sm-2 col-form-label">Gtip</label>
              <div class="col-sm-10">
                  <dx-select-box [dataSource]="datasourcemakinegrup" formControlName="makinegrupid" name="makinegrupid" (onValueChanged)="onValueChanged1($event)"
                  displayExpr="gtip" valueExpr="Id" [searchEnabled]="true"></dx-select-box>
              </div>
            </div>

            

            <div class="form-group row">
              <label for="aciklama" class="col-sm-2 col-form-label"> Açıklama 2</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="aciklama"
                  formControlName="aciklama" />
              </div>
            </div>

          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary font-weight-bold mr-2" [disabled]="!user.valid">
              Kaydet
            </button>
            <a class="btn btn-default font-weight-bold mr-2" routerLink="/userlist">Vazgeç</a>
          </div>
          <!-- /.card-footer -->
        </form>
        <div class="col-lg-12">


          <section class="content">
            <!-- Default box -->
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Malzeme Listesi</h3>
          
          
                        <div class="card-tools">
                          <button (click)=ekle() class="btn btn-primary font-weight-bold mr-2" >
                            Malzeme Ekle
                          </button>
                        </div>
                    </div>
                    <div class="card-body">
                      <dx-data-grid id="gridContainer" [dataSource]="datasourcemakineurun" keyExpr="makineurunid" [showBorders]="true"
                      [showRowLines]="true" [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [remoteOperations]="false"
                      [wordWrapEnabled]="true"       (onRowRemoving)="onRowRemoving($event)">
                      <dxo-editing [allowUpdating]="true" [useIcons]="true" [allowDeleting]="allowDeleting" >
                      </dxo-editing>
                      <dxo-filter-row [visible]="true"></dxo-filter-row>
                      <dxo-header-filter [visible]="true"></dxo-header-filter>
                      <dxo-search-panel [visible]="true" [width]="240" placeholder="Arama..."></dxo-search-panel>
                      <dxi-column dataField="urunkartkod" caption="Kod"></dxi-column>
                      <dxi-column dataField="urunkartaciklama" caption="Açıklama"></dxi-column>
                      <dxi-column dataField="urun_miktar" caption="miktar" > </dxi-column>
                      <dxi-column type="buttons">
                        <dxi-button name="delete"></dxi-button>
   <!--                      <dxi-button text="İncele" icon="edit" hint="düzenle" [onClick]="editIconClick">
                        </dxi-button> -->
                        
     <!--                    <dxi-button text="Sil"  icon="trash" hint="sil"  [onClick]="deleteIconClick">
                        </dxi-button> -->

                      </dxi-column>
                      <dxo-paging [pageSize]="12"></dxo-paging>
                      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]"></dxo-pager>
                    </dx-data-grid>
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer"></div>
                    <!-- /.card-footer-->
                </div>
            </div>
            <!-- /.card -->
          </section>


        </div>

      </div>

      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->