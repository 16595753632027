import { FrimaService } from './../firmalist/services/firma.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-firmasiparislist',
  templateUrl: './firmasiparislist.component.html',
  styleUrls: ['./firmasiparislist.component.scss']
})
export class FirmasiparislistComponent implements OnInit {
  datasource: any;
  constructor(private service: FrimaService,
    private route: ActivatedRoute) {  }

  ngOnInit() {
    
    const id = this.route.snapshot.paramMap.get("id");
    this.service
    .getById2("showfirmaurun", Number(id), "id")
    .subscribe((data) => {
      this.datasource=data;
      
    });

  }

}
