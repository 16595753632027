<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Üretim Bildirim Formu</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Üretim Bildirim Formu Listesi</h3>


        <div class="card-tools">
          <a href="#/uretimbildirimcreate" class="btn btn-block btn-primary">
            Yeni Üretim Bildirim Formu Ekle
          </a>
        </div>
      </div>
      <div class="card-body">
        <dx-data-grid id="gridContainer" [dataSource]="datasource" keyExpr="Id" [showBorders]="true"
          [showRowLines]="true" [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [remoteOperations]="false" (onRowRemoving)="onRowRemoving($event)"
          [wordWrapEnabled]="true">
          <dxo-editing [allowUpdating]="true" [useIcons]="true" mode="popup" [allowDeleting]="allowDeleting">
          </dxo-editing>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Arama..."></dxo-search-panel>

          <dxo-selection
          [selectAllMode]="true"
          [showCheckBoxesMode]="true"
          mode="multiple"
        ></dxo-selection>
          <dxi-column dataField="tarih" caption="Tarih" width="100"></dxi-column>
          <dxi-column dataField="gtip" caption="Gtip" width="150"></dxi-column>
          <dxi-column dataField="UrunAdi" caption="Gtip Açıklama"></dxi-column>
          <dxi-column dataField="Aciklama" caption="Açıklama"></dxi-column>
          <dxi-column type="buttons">
            
            <dxi-button text="İncele" icon="find" hint="İncele" [onClick]="cloneIconClick">
            </dxi-button>
            
            <dxi-button text="Exel"  hint="İncele" [onClick]="cloneIconClick1">
            </dxi-button>
            
            <dxi-button text="Pdf" hint="İncele" [onClick]="cloneIconClick2">
            </dxi-button>
            
            <dxi-button text="Form" hint="İncele" [onClick]="cloneIconClick3">
            </dxi-button>
            <dxi-button name="delete"  ></dxi-button>
          </dxi-column>
          <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
          <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]"></dxo-pager>
        </dx-data-grid>
      </div>
      <!-- /.card-body -->
      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->