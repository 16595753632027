import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UrunlistService } from '@pages/urunlist/services/urunlist.service';

@Component({
  selector: 'app-makineuruncreate',
  templateUrl: './makineuruncreate.component.html',
  styleUrls: ['./makineuruncreate.component.scss']
})
export class MakineuruncreateComponent implements OnInit {

  gridBoxValue: number;
    selectboxfirmavalue: number;
    isGridBoxOpened: boolean;
    gridColumns: any = [, 'Id', 'urunkartkod', 'urunkartaciklama'];
    gridDataSource: any;

    formGroup: FormGroup;

    datasourceurunkartliste: any;
    datasourcefirmaliste: any;

    user = new FormGroup({
        Id: new FormControl(),
        urun_id: new FormControl(),  
        makine_id: new FormControl(),        
        urun_miktar: new FormControl()
    });

    constructor(
        private fb: FormBuilder,
        private service: UrunlistService,
        private router: Router,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');

        this.service.getAll('urunkartliste').subscribe((data) => {
            this.datasourceurunkartliste = data;
            this.gridDataSource = data;
        });

        if (id) {
            this.service
                .getById2('urunlerid', Number(id), 'id')
                .subscribe((data) => {
                    this.user.patchValue(data[0]);
                    this.gridBoxValue = data[0].Id;
                });
        }
    }

    save() {
        const id = this.route.snapshot.paramMap.get('id');


        this.user.controls.urun_id.setValue(this.gridBoxValue[0].Id);
        
        this.user.controls.makine_id.setValue(id);


        this.service
        .create("makineuruncreate", this.user.value)
        .subscribe((result) => {}); 
        

        this.router.navigate(["/makinecreate/"+id]);
    }
    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation: string, controlName: string) {
        const control = this.formGroup.controls[controlName];
        return (
            control.hasError(validation) && (control.dirty || control.touched)
        );
    }

    isControlTouched(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.dirty || control.touched;
    }

    onGridBoxOptionChanged(e) {
        if (e.name === 'value') {
            this.gridBoxValue = e.value[0].Id;
            this.isGridBoxOpened = false;
            this.ref.detectChanges();
        }
    }

    gridBox_displayExpr(item) {
        return item && `${item.urunkartkod} <${item.urunkartaciklama}>`;
    }

    onValueChanged(e) {
        const previousValue = e.previousValue;
        this.selectboxfirmavalue = e.value;
        // Event handling commands go here
    }
}
