import {MenusidebarService} from './services/menusidebar.service';
import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user;
    menu: any;

    constructor(
        public appService: AppService,
        private service: MenusidebarService
    ) {}

    ngOnInit() {
        this.user = this.appService.user;

        this.service.getAll('menulist').subscribe((data) => {

        });
        this.menu = MENU;
    }
}

export const MENU = [
    {
        name: 'Anasayfa',
        path: 'dashboard'
    },
    {
        name: 'Firma Listesi',
        path: 'firmalist'
    },
    {
        name: 'Malzeme Yönetimi',
        path: 'urunlist'
    },
    {
        name: 'Ürün Reçetesi',
        path: 'makinelist'
    },
    {
        name: 'Stok',
        path: 'urunkartliste'
    },
    {
        name: 'Ürün Bildirim Formu',
        path: 'uretimbildirim'
    },
    {
        name: 'Dosya Yükleme',
        children: [
            {
                name: 'Stok Kart Yükleme',
                path: ['stokkartyukleme']
            },
            {
                name: 'Firma Yükleme',
                path: ['firmayukleme']
            },

            {
                name: 'Malzeme Yükleme',
                path: ['malzemeyukleme']
            },
            {
                name: 'Ürün Reçetesi',
                path: ['urunrecetesiyukleme']
            }
        ]
    },
    {
        name: 'Raporlar',
        path: 'raporlar'
    },
    {
        name: 'Yardım',
        path: 'yardim'
    },
    {
        name: 'SBBUP PALMA',
        path: 'yardim'
    }
];
