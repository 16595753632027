import { DateTime } from 'luxon';
import { DashBoardService } from './services/dashboard.service';
import {Component, OnInit} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    linkreferal;  
    datasource:any;
    datadeposit:[];
    totaldeposit:number=0;
    totalpacket:number=0;
    packet:number=0;
    totaluserdeposit:number=0;
    totaluserbonus:number=0;


    expireddate:DateTime;
    
    btcusdt:number=0;
    ethusdt:number=0;
    btctry:number=0;    
    ethtry:number=0;  
    btcimg:number=0;  
    ethimg:number=0;
    
    trxusdt:number=0; 
    trximg:number=0;
    
    trxtry:number=0; 
  

        
    usdttry:number=0; 
    usdtimg:number=0;


    constructor(private toastr: ToastrService,private service: DashBoardService) { }
  
    ngOnInit() {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        this.linkreferal = "https://backofice.wolumex.com/#/register/"+userObj.referal_code;


 


        


   

 
    }

  }
