import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FrimaService } from '@pages/firma/firmalist/services/firma.service';

@Component({
  selector: 'app-exel',
  templateUrl: './exel.component.html',
  styleUrls: ['./exel.component.scss']
})
export class ExelComponent implements OnInit {
  datasource: any;
  datasourcedetay: any;
  datasourceozet: any;
  constructor(private route: ActivatedRoute,private service: FrimaService, private router: Router) { }

  ngOnInit() {


    const id = this.route.snapshot.paramMap.get('id');

    this.service
    .getById2('showxlsdetay', Number(id), 'id')
    .subscribe((data) => {
        this.datasourcedetay=data;
 
    });

    this.service
    .getById2('showxlsozet', Number(id), 'id')
    .subscribe((data) => {
        this.datasourceozet=data;
 
    });

  }


  private static isChief(position) {
    return position && ["CEO", "CMO"].indexOf(position.trim().toUpperCase()) >= 0;
  };
  
  allowDeleting(e) {
    return !ExelComponent.isChief(e.row.data.Position);
  }

  onRowRemoving(e) {
 
    console.log("silme",e.key);
    this.service.getById2("uretimbildirimurundelete",e.key,"id").subscribe(res=>{
       console.log("silme",res);
    });
  
  }

}
