<div class="card card-custom gutter-b">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Kullanıcı Listesi</h3>
        </div>

    </div>
    <div class="card-body">
      <section class="content">
        <div class="container-fluid">
        <dx-data-grid
            id="gridContainer"
            [dataSource]="datasource"
            keyExpr="id"
            [showBorders]="true"
            [showRowLines]="true"
            [rowAlternationEnabled]="true"
            [hoverStateEnabled]="true"
            [remoteOperations]="false"
            [wordWrapEnabled]="true"
        >
        <dxo-editing [allowUpdating]="true" [useIcons]="true">
        </dxo-editing>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel
                [visible]="true"
                [width]="240"
                placeholder="Arama..."
            ></dxo-search-panel>
            <dxi-column dataField="name" >
            </dxi-column>
            <dxi-column dataField="email" > </dxi-column>
            <dxi-column dataField="title" > </dxi-column>
            <dxi-column type="buttons">

                <dxi-button text="İncele" icon="find" hint="İncele" [onClick]="cloneIconClick">

                </dxi-button>
              </dxi-column>
            <dxo-paging [pageSize]="12"></dxo-paging>
            <dxo-pager
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 25, 50]"
            ></dxo-pager>
        </dx-data-grid>
        </div>
        </section>
    </div>
</div>




