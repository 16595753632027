import { DashBoardService } from './../../dashboard/services/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-urunkartliste',
  templateUrl: './urunkartliste.component.html',
  styleUrls: ['./urunkartliste.component.scss']
})
export class UrunkartlisteComponent implements OnInit {

  datasource: any;
  constructor(private service: DashBoardService,private router: Router) {this.cloneIconClick = this.cloneIconClick.bind(this) }

  ngOnInit() {
    this.service.getAll('urunkartliste').subscribe((data) => {
      this.datasource = data;
  });
  }
  cloneIconClick(e) {
    this.router.navigate(["/urunkartekle/"+e.row.data.Id]);
  
  
  }
}
