import { UretimbildirimcreateComponent } from './pages/makinesiparis/uretimbildirimcreate/uretimbildirimcreate.component';
import { UretimbildirimComponent } from './pages/makinesiparis/uretimbildirim/uretimbildirim.component';
import { PdfComponent } from './pages/makinesiparis/pdf/pdf.component';
import { BildirimComponent } from './pages/makinesiparis/bildirim/bildirim.component';
import { ExelComponent } from './pages/makinesiparis/exel/exel.component';
import { UrunrecetesiyuklemeComponent } from './pages/dosyayukleme/urunrecetesiyukleme/urunrecetesiyukleme.component';
import { MalzemeyuklemeComponent } from './pages/dosyayukleme/malzemeyukleme/malzemeyukleme.component';
import { StokkartyuklemeComponent } from './pages/dosyayukleme/stokkartyukleme/stokkartyukleme.component';
import { FirmayuklemeComponent } from './pages/dosyayukleme/firmayukleme/firmayukleme.component';
import { MakinesiparispdfComponent } from './pages/makinesiparis/makinesiparispdf/makinesiparispdf.component';
import { FirmasiparislistComponent } from './pages/firma/firmasiparislist/firmasiparislist.component';
import { MakinesipariscreateComponent } from './pages/makinesiparis/makinesipariscreate/makinesipariscreate.component';
import { MakinesiparislistComponent } from './pages/makinesiparis/makinesiparislist/makinesiparislist.component';
import { MakineuruncreateComponent } from './pages/makine/makineuruncreate/makineuruncreate.component';
import { UrunkartlisteComponent } from './pages/urunkart/urunkartliste/urunkartliste.component';
import { UrunkartekleComponent } from './pages/urunkart/urunkartekle/urunkartekle.component';
import { MakineurunComponent } from './pages/makine/makineurun/makineurun.component';
import { UruncreateComponent } from './pages/urunlist/uruncreate/uruncreate.component';
import { MakinecreateComponent } from './pages/makine/makinecreate/makinecreate.component';
import { MakinelistComponent } from './pages/makine/makinelist/makinelist.component';
import { FirmacreateComponent } from './pages/firma/firmacreate/firmacreate.component';
import { FirmalistComponent } from './pages/firma/firmalist/firmalist.component';
import { UrunlistComponent } from './pages/urunlist/urunlist.component';
import { UseraddComponent } from './pages/userlist/useradd/useradd.component';
import { TimecountComponent } from './components/timecount/timecount.component';
import { UserlistComponent } from './pages/userlist/userlist.component';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {ButtonComponent} from './components/button/button.component';

import {DatePipe, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {DropdownMenuComponent} from './components/dropdown/dropdown-menu/dropdown-menu.component';
import { DxDataGridModule, DxDropDownBoxModule, DxSelectBoxModule, DxTabPanelModule, DxTemplateModule, DxTreeListModule } from 'devextreme-angular';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CountDownComponent } from '@components/CountDownComponent/CountDownComponent.component';
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        ButtonComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        PrivacyPolicyComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        DropdownComponent,
        DropdownMenuComponent,
        UserlistComponent,
        CountDownComponent,
        TimecountComponent,
        UseraddComponent,
        UrunlistComponent,
        FirmalistComponent,
        FirmacreateComponent,
        MakinelistComponent,
        MakinecreateComponent,
        UruncreateComponent,
        MakineurunComponent,
        UrunkartekleComponent,
        UrunkartlisteComponent,
        MakineuruncreateComponent,
        MakinesiparislistComponent,
        MakinesipariscreateComponent,
        FirmasiparislistComponent,
        MakinesiparispdfComponent,
        FirmayuklemeComponent,
        StokkartyuklemeComponent,
        MalzemeyuklemeComponent,
        UrunrecetesiyuklemeComponent,
        ExelComponent,
        BildirimComponent,
        PdfComponent,
        UretimbildirimComponent,
        UretimbildirimcreateComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        DxDataGridModule,
        BrowserAnimationsModule,
        DxTreeListModule,
        DxTabPanelModule,
        DxTemplateModule,
        DxDropDownBoxModule,
        DxSelectBoxModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        })
    ],
    providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},DatePipe],
    bootstrap: [AppComponent]
})
export class AppModule {}
