<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Malzeme Ekleme/Güncelleme</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Malzeme Ekleme/Güncelleme</h3>

      </div>
      <div class="card-body">

        <form action="#" class="form-horizontal" [formGroup]="user" (ngSubmit)="save()">
          <div class="card-body">
            
            <div class="form-group row">
              <label for="malzeme" class="col-sm-2 col-form-label">Ürün</label>
              <div class="col-sm-10">
 <!--                <dx-select-box [dataSource]="datasourceurunkartliste" formControlName="urunkart_id" name="urunkart_id"
                displayExpr="urunkartacklama" valueExpr="Id" [searchEnabled]="true"></dx-select-box> -->


                <dx-drop-down-box
                [(value)]="gridBoxValue"
                [(opened)]="isGridBoxOpened"
                valueExpr="Id"
                [deferRendering]="false"
                [displayExpr]="gridBox_displayExpr"
                placeholder="Select a value..."
                [showClearButton]="true"
                [dataSource]="gridDataSource"
                (onOptionChanged)="onGridBoxOptionChanged($event)"
                formControlName="urunkart_id" name="urunkart_id"
              >
                <div *dxTemplate="let data of 'content'">
                  <dx-data-grid
                    [dataSource]="gridDataSource"
                    [columns]="gridColumns"
                    [hoverStateEnabled]="true"
                    [(selectedRowKeys)]="gridBoxValue"
                    height="100%"
                  >
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-scrolling mode="virtual"></dxo-scrolling>
                    <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                  </dx-data-grid>
                </div>
              </dx-drop-down-box>


              </div>
              
            </div>
            
            <div class="form-group row">
              <label for="firma" class="col-sm-2 col-form-label">Firma</label>
              <div class="col-sm-10">
                  <dx-select-box [dataSource]="datasourcefirmaliste" formControlName="firma_id" name="firma_id" (onValueChanged)="onValueChanged($event)"
                  displayExpr="firmaadi" valueExpr="Id" [searchEnabled]="true"></dx-select-box>
              </div>
            </div>

            <div class="form-group row">
              <label for="tarih" class="col-sm-2 col-form-label">Tarih</label>
              <div class="col-sm-10">
                <input type="date" class="form-control form-control-lg " placeholder="" name="tarih"
                  formControlName="tarih" />
              </div>
            </div>

            <div class="form-group row">
              <label for="gtip" class="col-sm-2 col-form-label">Gtip</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="gtip"
                  formControlName="gtip" />
              </div>
            </div>

            <div class="form-group row">
              <label for="gtip_aciklama" class="col-sm-2 col-form-label">Gtip Açıklama</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="gtip_aciklama"
                  formControlName="gtip_aciklama" />
              </div>
            </div>
            
            <div class="form-group row">
              <label for="sbif" class="col-sm-2 col-form-label">Açıklama</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="sbif"
                  formControlName="sbif" />
              </div>
            </div>
            
            <div class="form-group row">
              <label for="fisno" class="col-sm-2 col-form-label">Fiş No</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="fisno"
                  formControlName="fisno" />
              </div>
            </div>

            
            <div class="form-group row">
              <label for="girisadet" class="col-sm-2 col-form-label">Giriş Miktar</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="girisadet"
                  formControlName="girisadet" />
              </div>
            </div>
            
            <div class="form-group row">
              <label for="birim" class="col-sm-2 col-form-label">Birim</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="birim"
                  formControlName="birim" />
              </div>
            </div>

            <div class="form-group row">
              <label for="mensei" class="col-sm-2 col-form-label">Menşei</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="mensei"
                  formControlName="mensei" />
              </div>
            </div>

            <div class="form-group row">
              <label for="birimfiyat" class="col-sm-2 col-form-label">Birim Fiyat</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="birimfiyat"
                  formControlName="birimfiyat" />
              </div>
            </div>

            <div class="form-group row">
              <label for="tutar" class="col-sm-2 col-form-label">Tutar</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="tutar"
                  formControlName="tutar" />
              </div>
            </div>

            <div class="form-group row">
              <label for="bakiye" class="col-sm-2 col-form-label">Bakiye</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="bakiye"
                  formControlName="bakiye" />
              </div>
            </div>

            <div class="form-group row">
              <label for="kalan" class="col-sm-2 col-form-label">Kalan</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-lg " placeholder="" name="kalan"
                  formControlName="kalan" />
              </div>
            </div>




          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" class="btn btn-primary font-weight-bold mr-2" [disabled]="!user.valid">
              Kaydet
            </button>
            <a class="btn btn-default font-weight-bold mr-2" routerLink="/urunlist">Vazgeç</a>
          </div>
          <!-- /.card-footer -->
        </form>





      </div>

      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->