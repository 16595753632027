<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Üretim Bildirim Exel</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Üretim Bildirim Özet</h3>
      </div>
      <div class="card-body">
        <dx-data-grid id="gridContainer" [dataSource]="datasourceozet"  [showBorders]="true"
          [showRowLines]="true" [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [remoteOperations]="false"
          [wordWrapEnabled]="true">
          <dxo-editing [allowUpdating]="false" [useIcons]="false" mode="popup">
          </dxo-editing>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Arama..."></dxo-search-panel>

          <dxo-selection
          [selectAllMode]="true"
          [showCheckBoxesMode]="true"
          mode="multiple"
        ></dxo-selection>
          <dxi-column dataField="ReferansFormTipi" caption="ReferansFormTipi"></dxi-column>
          <dxi-column dataField="ReferansFormNo" caption="ReferansFormNo"></dxi-column>
          <dxi-column dataField="ReferansFormYil" caption="ReferansFormYil"></dxi-column>
          <dxi-column dataField="ReferansSiraNo" caption="ReferansSiraNo"></dxi-column>
          <dxi-column dataField="gtip" caption="gtip"></dxi-column>
          <dxi-column dataField="Cins" caption="Cins"></dxi-column>
          <dxi-column dataField="Mensei" caption="Mensei"></dxi-column>
          <dxi-column dataField="BirinciMiktar" caption="BirinciMiktar"></dxi-column>
          <dxi-column dataField="BirinciFireMiktar" caption="BirinciFireMiktar"></dxi-column>
          <dxi-column dataField="BirinciBirim" caption="BirinciBirim"></dxi-column>
   
          <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
          <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]"></dxo-pager>
        </dx-data-grid>
      </div>
      <!-- /.card-body -->
      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>



<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Üretim Bildirim Detay</h3>
      </div>
      <div class="card-body">
        <dx-data-grid id="gridContainer" [dataSource]="datasourcedetay" keyExpr="UrunId" [showBorders]="true"
          [showRowLines]="true" [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [remoteOperations]="false"  (onRowRemoving)="onRowRemoving($event)"
          [wordWrapEnabled]="true">
          <dxo-editing [allowUpdating]="false" [useIcons]="false" mode="popup"  [allowDeleting]="allowDeleting">
          </dxo-editing>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Arama..."></dxo-search-panel>

          <dxo-selection
          [selectAllMode]="true"
          [showCheckBoxesMode]="true"
          mode="multiple"
        ></dxo-selection>
          <dxi-column dataField="makinekod" caption="Ürün Kod"></dxi-column>
          <dxi-column dataField="makineadi" caption="Ürün Adı"></dxi-column>
          <dxi-column dataField="sbif" caption="SBIF"></dxi-column>
          <dxi-column dataField="kod" caption="Kod"></dxi-column>
          <dxi-column dataField="malzeme" caption="Maleme"></dxi-column>
          <dxi-column dataField="kalem" caption="Kalem"></dxi-column>
          <dxi-column dataField="mensei" caption="Menşei"></dxi-column>
          <dxi-column dataField="makinesiparis_miktar" caption="Miktar"></dxi-column>
          <dxi-column dataField="birim" caption="Birim"></dxi-column>
          <dxi-column dataField="tarih" caption="Tarih"></dxi-column>
          <dxi-column dataField="gtip" caption="Gtip"></dxi-column>
          <dxi-column type="buttons">
 
            <dxi-button name="delete"  ></dxi-button>
          </dxi-column>
          <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
          <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]"></dxo-pager>
        </dx-data-grid>
      </div>
      <!-- /.card-body -->
      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->