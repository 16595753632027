import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FrimaService } from '@pages/firma/firmalist/services/firma.service';
import * as JsonToXML from "js2xmlparser";
import { saveAs } from 'file-saver'
@Component({
  selector: 'app-bildirim',
  templateUrl: './bildirim.component.html',
  styleUrls: ['./bildirim.component.scss']
})
export class BildirimComponent implements OnInit {

  datasource: any;
  constructor(private route: ActivatedRoute,private service: FrimaService, private router: Router) { }

  datajson:any;


  ngOnInit() {

    const id = this.route.snapshot.paramMap.get('id');

    this.service
    .getById2('showxml', Number(id), 'id')
    .subscribe((data) => {
        this.datasource=data;
        this.datajson=JSON.stringify(data);
        
        var myJsonString = JSON.stringify(data);

        
        console.log("xml2",myJsonString)
    });

  }


  saveFile() {

    //console.log("dddd",JsonToXML.parse("person", this.datasource));


   
    const data = JsonToXML.parse("UBFBilgileri", this.datasource);
    const blob1 = new Blob([data], {type: 'text/plain'});
  
    saveAs(blob1, "gumrukxml.xsd");
    }

}
