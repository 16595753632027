<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Stok Yükleme</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Stok Yükleme</h3>
        <div>

        </div>
        <div class="card-tools">
          <input type="file" (change)="onFileChange($event)" multiple="false" class="btn btn-block btn-primary" />
        </div>
      </div>
      <div class="card-body">

        <dx-data-grid #dataGridVar id="dataGrid" [dataSource]="datasource" [showBorders]="true" [showRowLines]="true"
          [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [remoteOperations]="false" [wordWrapEnabled]="true">
          <dxo-editing [allowUpdating]="false" [useIcons]="true">
          </dxo-editing>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Arama..."></dxo-search-panel>
          <dxi-column dataField="Urunkartkod" ></dxi-column>
          <dxi-column dataField="Urunkartaciklama" ></dxi-column>
          <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="stokkart"></dxo-export>
          <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]"></dxo-pager>
        </dx-data-grid>


       
      </div>

      <div class="card-footer">

        <button class="btn btn-primary font-weight-bold mr-2" (click)="export()">
          Kaydet
        </button>
        <a class="btn btn-default font-weight-bold mr-2" routerLink="/firmalist">Vazgeç</a>
      </div>
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->