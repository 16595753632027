import { UrunlistService } from './services/urunlist.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-urunlist',
  templateUrl: './urunlist.component.html',
  styleUrls: ['./urunlist.component.scss']
})
export class UrunlistComponent implements OnInit {

  name = 'This is XLSX TO JSON CONVERTER';
  willDownload = false;

  datasource: any;
  constructor(private service: UrunlistService,private router: Router) {this.cloneIconClick = this.cloneIconClick.bind(this) }

  ngOnInit() {
    this.service.getAll('urunlerlist').subscribe((data) => {
      this.datasource = data;
  });
  }
  cloneIconClick(e) {
    this.router.navigate(["/uruncreate/"+e.row.data.Id]);
  
  
  }


  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);

console.log(dataString);

      document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      this.setDownload(dataString);
    }
    reader.readAsBinaryString(file);
  }

  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
      el.setAttribute("download", 'xlsxtojson.json');
    }, 1000)
  }
}
