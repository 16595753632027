<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Ürün Listesi</h1>
      </div>

    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Ürün istesi</h3>


        <div class="card-tools">
          <a href="#/makinesipariscreate/{{urunid}}" class="btn btn-block btn-primary">
            Yeni Ürün Ekle
          </a>
        </div>
      </div>
      <div class="card-body">
        <dx-data-grid id="gridContainer" [dataSource]="datasource" keyExpr="id" [showBorders]="true"
          [showRowLines]="true" [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [remoteOperations]="false"
          [wordWrapEnabled]="true"  (onRowRemoving)="onRowRemoving($event)">
          <dxo-editing [allowUpdating]="true" [useIcons]="true" mode="popup" [allowDeleting]="allowDeleting" >
          </dxo-editing>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Arama..."></dxo-search-panel>

          <dxo-selection
          [selectAllMode]="true"
          [showCheckBoxesMode]="true"
          mode="multiple"
        ></dxo-selection>
          <dxi-column dataField="makinesiparistarih" caption="Tarih"></dxi-column>
          <dxi-column fisno="fisno" caption="Fiş No"></dxi-column>
          <dxi-column dataField="uretimformno" caption="Üretim Form No"></dxi-column>
          <dxi-column dataField="gtip" caption="Gtip"></dxi-column>
          <dxi-column dataField="makinegrupadi" caption="Açıklama"></dxi-column>
          <dxi-column dataField="makinesiparisaciklama" caption="Açıklama2"></dxi-column>
          <dxi-column dataField="makinekod" caption="Kod"></dxi-column>
          <dxi-column dataField="makineadi" caption="Makine Adı"></dxi-column>
          <dxi-column dataField="birimaciklama" caption="Birim"></dxi-column>
          <dxi-column dataField="makinesiparisadet" caption="Adet"></dxi-column>
          <dxi-column dataField="gerceklesen" caption="Gerçekleşen Adet"></dxi-column>
          <dxi-column dataField="kalan" caption="Kalan Adet"></dxi-column>
          <dxi-column dataField="durumu" caption="Durumu"></dxi-column>
          <dxi-column type="buttons">
            
            <dxi-button text="İncele" icon="find" hint="İncele" [onClick]="cloneIconClick">
            </dxi-button>
            
            <dxi-button name="delete"  ></dxi-button>
          </dxi-column>
          <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
          <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]"></dxo-pager>
        </dx-data-grid>
      </div>
      <!-- /.card-body -->
      <div class="card-footer"></div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->