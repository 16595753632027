import { MakineService } from './../services/makine.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-makinelist',
  templateUrl: './makinelist.component.html',
  styleUrls: ['./makinelist.component.scss']
})
export class MakinelistComponent implements OnInit {

  datasource: any;
  constructor(private service: MakineService,private router: Router) {this.cloneIconClick = this.cloneIconClick.bind(this) }

  ngOnInit() {
    this.service.getAll('makinelist').subscribe((data) => {
      this.datasource = data;
  });
  }
  cloneIconClick(e) {
    this.router.navigate(["/makinecreate/"+e.row.data.Id]);
  
  
  }
}
