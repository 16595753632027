


        <!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>XML Oluşturma</h1>
        </div>
  
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Main content -->
  <section class="content">
    <!-- Default box -->
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">XML Oluşturma</h3>

        </div>
        <div class="card-body">

            <button   (click)='saveFile()' color="secondary">XML Oluştur</button>
        </div>
        <!-- /.card-body -->
        <div class="card-footer"></div>
        <!-- /.card-footer-->
      </div>
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->