import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FrimaService } from '@pages/firma/firmalist/services/firma.service';

@Component({
  selector: 'app-uretimbildirim',
  templateUrl: './uretimbildirim.component.html',
  styleUrls: ['./uretimbildirim.component.scss']
})
export class UretimbildirimComponent implements OnInit {

  datasource: any;
  constructor(private service: FrimaService, private router: Router) {
      (this.cloneIconClick1 = this.cloneIconClick1.bind(this)),
          (this.cloneIconClick2 = this.cloneIconClick2.bind(this)),
          (this.cloneIconClick3 = this.cloneIconClick3.bind(this)),
          (this.cloneIconClick = this.cloneIconClick.bind(this)),
          (this.pdfIconClick = this.pdfIconClick.bind(this));
  }

  ngOnInit() {
      this.service.getAll('uretimbildirimlist').subscribe((data) => {
          this.datasource = data;
      });
  }


  cloneIconClick(e) {
      this.router.navigate(['/makinesiparislist/' + e.row.data.Id]);
  }

  cloneIconClick1(e) {
      this.router.navigate(['/exel/' + e.row.data.Id]);
  }

  cloneIconClick2(e) {
      this.router.navigate(['/pdf/' + e.row.data.Id]);
  }

  cloneIconClick3(e) {
      this.router.navigate(['/bildirim/' + e.row.data.Id]);
  }

  pdfIconClick(e) {
      this.router.navigate(['/makinesiparispdf/' + e.row.data.Id]);
  }


  private static isChief(position) {
    return position && ["CEO", "CMO"].indexOf(position.trim().toUpperCase()) >= 0;
  };
  
  allowDeleting(e) {
    return !UretimbildirimComponent.isChief(e.row.data.Position);
  }

  onRowRemoving(e) {
 
    this.service.getById2("uretimbildirimdelete",e.key,"id").subscribe(res=>{
       console.log("silme",res);
    });
  
  }

}
