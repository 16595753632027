import {Inject} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {Base} from '@/models/models';
import {environment} from 'environments/environment';
import {AuthenticationService} from '@guards/authentication.service';

@Inject({
    providedIn: 'root'
})
export class BaseDataService<T extends Base> {
    filter: string | undefined;
    apiUrl = environment.apiUrl;

    constructor(
        private httpClient: HttpClient,
        private url: string,
        private auth: AuthenticationService
    ) {}

    httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    getAll(method: string): Observable<any> {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        const token = userObj.token;
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
        return this.httpClient.get<any>(this.apiUrl + '/' + method, {
            headers: httpHeaders
        });
    }

    getById(method: string, id: number, filter: string): Observable<T> {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        const token = userObj.token;
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
        return this.httpClient.get<any>(
            this.apiUrl + '/' + method + '?' + filter + '=' + id,
            {
                headers: httpHeaders
            }
        );
    }

    getById2(method: string, id: number, filter: string): Observable<T> {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        const token = userObj.token;
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
        return this.httpClient.get<any>(this.apiUrl + '/' + method + '/' + id, {
            headers: httpHeaders
        });
    }

    getByGuidId(method: string, id: string, filter: string): Observable<T> {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        const token = userObj.token;
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
        return this.httpClient.get<any>(
            this.apiUrl + '/' + method + '?' + filter + '=' + id,
            {
                headers: httpHeaders
            }
        );
    }

    getByText(method: string, text: string, filter: string): Observable<T> {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        const token = userObj.token;
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
        return this.httpClient.get<any>(
            this.apiUrl + '/' + method + '?' + filter + '=' + text,
            {
                headers: httpHeaders
            }
        );
    }

    create(method: string, data): Observable<T> {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        const token = userObj.token;
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
        return this.httpClient.post<any>(this.apiUrl + '/' + method, data, {
            headers: httpHeaders
        });
    }

    update(method: string, data): Observable<T> {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        const token = userObj.token;
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
        return this.httpClient.put<T>(this.apiUrl + '/' + method, data, {
            headers: httpHeaders
        });
    }

    deleteById<T>(method: string, id: string) {
        const user: any = localStorage.getItem('user');
        const userObj = JSON.parse(user);

        const token = userObj.token;
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });

        return this.httpClient.delete<T>(
            this.apiUrl + '/' + method + '/' + id,
            {headers: httpHeaders}
        );
    }

    getcoin(kripto: string, parite: string): Observable<any> {
        // return this.httpClient.get<any>("");
        return this.httpClient.get(
            'https://api.coingecko.com/api/v3/coins/markets?vs_currency=' +
                parite +
                '&ids=' +
                kripto
        );
    }
}
